import React, { FC } from 'react'
import { PaymentType } from '../../types'
import { GPayIcon } from '../GPay'
import { ApplePayIcon } from '../ApplePay'
import { useBillingFormState, useBillingInfo, useApplePay } from '../../hooks'
import { StyledPaymentIcons } from './styles'
import paypalIcon from '../PayPal/pay-pal-horizontal.png'

type SubPaymentIconsProps = {
  paymentMethod: PaymentType
}

export const SubPaymentIcons: FC<SubPaymentIconsProps> = ({
  paymentMethod
}) => {
  const {
    enableAllPaymentLayout,
    enableGPay,
    enableGPayInNativeApp,
    enableApplePay,
    nativeApp,
    enablePaypalInNativeApp
  } = useBillingFormState()
  const applePayAvailable = useApplePay()
  const { paymentMethods } = useBillingInfo() || { paymentMethods: [] }
  const showPayPalLogo = () =>
    Boolean(paymentMethods?.includes(PaymentType.pay_pal)) &&
    (!nativeApp || enablePaypalInNativeApp)

  const showGPayIcon = nativeApp ? enableGPayInNativeApp : enableGPay

  const showApplePayIcon =
    applePayAvailable && (nativeApp ? false : enableApplePay) //TODO: use enableApplePayInNativeApp instead of false

  if (paymentMethod === 'credit_card' && enableAllPaymentLayout) {
    return (
      <StyledPaymentIcons>
        {showApplePayIcon && <ApplePayIcon data-testid="applepay-icon" />}
        {showGPayIcon && <GPayIcon data-testid="gpay-icon" />}
        {showPayPalLogo() && (
          <img
            data-testid="paypal-icon"
            height={24}
            src={paypalIcon}
            alt="paypal-icon"
          />
        )}
      </StyledPaymentIcons>
    )
  }

  return null
}
