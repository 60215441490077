import React from 'react'
import { useBillingFormState, useGetText } from '../../hooks'
import * as Styled from './styles'
import Button from '@veneer/core/dist/scripts/button'
import { PayPalButton } from '../PayPal/payPalButton'

export const ExpressCheckout = () => {
  const { onHPCheckout } = useBillingFormState()
  const getText = useGetText()

  return (
    <Styled.CheckoutContainer>
      <Styled.Text>{getText('checkout_card.express_checkout')}</Styled.Text>
      <Styled.PaymentButtons>
        <Styled.StyledPayPalButtonWrapper>
          <PayPalButton
            key="paypal-button"
            data-testid="paypal-button"
            isExpressCheckout={true}
            tagline={false}
          />
        </Styled.StyledPayPalButtonWrapper>
      </Styled.PaymentButtons>
      <Styled.Text>{getText('checkout_card.or')}</Styled.Text>
      <Button
        expanded
        data-testid="hp-checkout-button"
        data-analyticsid="hp-checkout-button"
        disabled={false}
        loading={false}
        onClick={onHPCheckout}
      >
        {getText('checkout_card.hp_checkout')}
      </Button>
    </Styled.CheckoutContainer>
  )
}
