import React, { FC } from 'react'
import * as Styled from './styles'
import IconExternalLink from '@veneer/core/dist/scripts/icons/icon_external_link'
import { useGetText } from '../../hooks'

interface LinkPaymentProviderButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  loading: boolean
}

export const LinkPaymentProviderButton: FC<LinkPaymentProviderButtonProps> = ({
  onClick,
  loading
}) => {
  const getText = useGetText()

  return (
    <div data-testid="linkpaymentprovider-button">
      <Styled.LinkPaymentProviderButton
        className={'instant-ink-enroll-hpx-button-override'}
        data-testid="save-button"
        data-analyticsid="SaveButton"
        disabled={false}
        onClick={onClick}
        loading={loading}
      >
        {getText('billing_form.link_payment_provider')}
        {!loading && (
          <IconExternalLink
            data-testid="link-payment-provider-icon"
            color="white"
          />
        )}
      </Styled.LinkPaymentProviderButton>
    </div>
  )
}
