import styled from 'styled-components'

export const CheckoutContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`

export const Text = styled.p`
  && {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 16px 0 8px 0;
  }
`

export const PaymentButtons = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 8px;
  }
`

export const StyledPayPalButtonWrapper = styled.div`
  && {
    width: 100%;

    button {
      height: 48px;
      border-radius: 12px;
    }
  }
`
