import React, { FC, useState } from 'react'
import { LinkPaymentProviderButton } from './linkPaymentProviderButton'
import { ApplePayIcon } from '../ApplePay'
import { GPayIcon } from '../GPay'
import paypalIcon from '../PayPal/pay-pal-horizontal.png'

import * as Styled from './styles'
import { useBillingFormState, useDispatch, useLocaleUrl } from '../../hooks'
import { StatusModal } from '../StatusModal'
import {
  createPhcEventStatusAction,
  fetchBillingInfoAction,
  fetchPhcEventStatusAction,
  setErrorNotificationAction
} from '../../actions'

export type ModalState =
  | 'checkStatus'
  | 'gpaySuccess'
  | 'paypalSuccess'
  | 'applePaySuccess'
  | 'closed'

export const LinkPaymentProvider: FC<{
  ['data-testid']: string
}> = (props) => {
  const {
    enablePaypalInNativeApp,
    enableGPayInNativeApp,
    billingService,
    onboardingcenterURL,
    appName
  } = useBillingFormState()
  const locale = useLocaleUrl()

  const enableApplePayInNativeApp = false // WIP
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalState, setModalState] = useState<ModalState>('closed')
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false)

  const checkPhcEventStatus = async () => {
    if (confirmButtonLoading) return
    setConfirmButtonLoading(true)

    try {
      const phcEvent = await dispatch(fetchPhcEventStatusAction())
      if (['success', 'successNoPaymentChanged'].includes(phcEvent.status)) {
        const billingInfo = await dispatch(fetchBillingInfoAction())
        switch (billingInfo.paymentType) {
          case 'pay_pal':
            setModalState('paypalSuccess')
            break
          case 'credit_card':
            // WIP - check if is gpay or applepay
            setModalState('gpaySuccess')
            break
        }
      } else if (phcEvent.status === 'waiting') {
        // do nothing
      } else {
        // create new phc event order id
        await dispatch(createPhcEventStatusAction())
        throw new Error('Phc Event Error')
      }
    } catch {
      dispatch(setErrorNotificationAction(true))
      closeModal()
    } finally {
      setConfirmButtonLoading(false)
    }
  }

  const openModal = () => {
    setShowModal(true)
    setModalState('checkStatus')
  }

  const closeModal = () => {
    setShowModal(false)
    setModalState('closed')
  }

  const onCheckStatus = async () => {
    await checkPhcEventStatus()
  }

  const onDone = () => {
    closeModal()
  }

  const onLinkPaymentProvider = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.persist()
    setLoading(true)
    try {
      const {
        data: { oneTimeToken }
      } = await billingService.createLinkPaymentOneTimeToken()
      openModal()
      window.open(
        `${onboardingcenterURL}/${locale}/link-payment?token=${oneTimeToken}&appName=${appName}`,
        '_blank',
        'noopener'
      )
    } catch (error) {
      dispatch(setErrorNotificationAction(true))
      closeModal()
    }
    setLoading(false)
  }

  return (
    <div data-testid={props['data-testid']}>
      <StatusModal
        show={showModal}
        closeModal={closeModal}
        onDone={onDone}
        onCheckStatus={onCheckStatus}
        modalState={modalState}
        confirmButtonLoading={confirmButtonLoading}
        data-testid="link-payment-status-modal"
      />
      <LinkPaymentProviderButton
        onClick={onLinkPaymentProvider}
        loading={loading}
      />
      <Styled.IconsContainer>
        {enableApplePayInNativeApp && (
          <ApplePayIcon data-testid="applepay-icon" />
        )}
        {enableGPayInNativeApp && <GPayIcon data-testid="gpay-icon" />}
        {enablePaypalInNativeApp && (
          <img
            data-testid="paypal-icon"
            height={24}
            src={paypalIcon}
            alt="paypal-icon"
          />
        )}
      </Styled.IconsContainer>
    </div>
  )
}
