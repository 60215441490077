import React, { ReactElement } from 'react'
import {
  useBillingFormState,
  useBillingInfo,
  useDispatch,
  useErrorNotificationState,
  useGetText,
  usePaymentType,
  useApplePay
} from '../../hooks'
import { PaymentType } from '../../types'
import { PgsForm } from '../PgsForm'
import { StyledStep } from '../Shared/styles'
import {
  NotificationContainer,
  OrBar,
  OrSection,
  PaymentButtons,
  PgsFormSection
} from './styles'
import { PayPalButton } from '../PayPal/payPalButton'
import { GPayButton } from '../GPay'
import { ApplePayButton } from '../ApplePay'
import { LinkPaymentProvider } from '../LinkPaymentProvider'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'
import { setErrorNotificationAction } from '../../actions'

export const AllPaymentLayout = () => {
  const applePayAvailable = useApplePay()
  const {
    enableGPay,
    enableGPayInNativeApp,
    enableApplePay,
    nativeApp,
    enablePaypalInNativeApp
  } = useBillingFormState()
  const errorNotification = useErrorNotificationState()
  const { paymentMethods } = useBillingInfo() || { paymentMethods: [] }
  const paymentType = usePaymentType()
  const getText = useGetText()
  const showPayPal = () =>
    Boolean(paymentMethods?.includes(PaymentType.pay_pal)) &&
    (!nativeApp || enablePaypalInNativeApp)
  const paymentButtons: Array<ReactElement> = []
  const shouldShowApplePay = nativeApp
    ? false //TODO: use (enableApplePayInNative && applePayAvailable) instead of false
    : enableApplePay && applePayAvailable
  const shouldShowGPay = nativeApp ? enableGPayInNativeApp : enableGPay
  const dispatch = useDispatch()

  const onShowError = () => {
    dispatch(setErrorNotificationAction(true))
  }

  const addButtonsToWeb = () => {
    if (shouldShowApplePay) {
      paymentButtons.push(
        <ApplePayButton key="applepay-button" data-testid="applepay-button" />
      )
    }
    if (shouldShowGPay) {
      paymentButtons.push(
        <GPayButton key="gpay-button" data-testid="gpay-button" />
      )
    }
    if (showPayPal()) {
      paymentButtons.push(
        <PayPalButton
          key="paypal-button"
          data-testid="paypal-button"
          onShowError={onShowError}
        />
      )
    }
  }

  const addButtonsToNative = () => {
    if (shouldShowApplePay || shouldShowGPay) {
      paymentButtons.push(
        <LinkPaymentProvider
          key="linkpaymentprovider"
          data-testid="linkpaymentprovider"
        />
      )
    } else if (showPayPal()) {
      paymentButtons.push(
        <PayPalButton
          key="paypal-button"
          data-testid="paypal-button"
          onShowError={onShowError}
        />
      )
    }
  }

  if (paymentType === PaymentType.credit_card) {
    if (nativeApp) {
      addButtonsToNative()
    } else {
      addButtonsToWeb()
    }
  }
  const hasMorePaymentMethods = paymentButtons.length > 0

  return (
    <StyledStep>
      {errorNotification && (
        <NotificationContainer>
          <InlineNotification
            id="pgs-form-error"
            title={getText('error_message.card_error')}
            type="negative"
            closeButton={false}
          />
        </NotificationContainer>
      )}
      {hasMorePaymentMethods && (
        <>
          <PaymentButtons>{paymentButtons}</PaymentButtons>
          <OrSection>
            <OrBar />
            <span>{getText('all_payment_layout.or')}</span>
            <OrBar />
          </OrSection>
        </>
      )}
      <PgsFormSection hasMorePaymentMethods={hasMorePaymentMethods}>
        <PgsForm />
      </PgsFormSection>
    </StyledStep>
  )
}
