import { localeToStringsJson } from '../assets/locale'

export function supportedCountries(locales: any): string[] {
  const countries: string[] = []

  Object.keys(locales).forEach(function (key) {
    const countryParts = key.split('_')
    const country = countryParts[1]

    if (!countries.includes(country)) {
      countries.push(country)
    }
  })

  return countries
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setFallbackLocale(language: string, country: string) {
  const locale = language + '_' + country

  if (!supportedCountries(localeToStringsJson).includes(country)) {
    language = 'en'
    country = 'US'
  } else if (!(locale in localeToStringsJson)) {
    Object.keys(localeToStringsJson).forEach(function (key) {
      const countryParts = key.split('_')

      if (country === countryParts[1]) {
        language = countryParts[0]
        return { language, country }
      }
    })
  }

  return { language, country }
}
