import React, { useEffect, useState } from 'react'
import {
  useBillingFormState,
  useBillingInfo,
  useContainerSize,
  useCriticalScopes,
  useDispatch,
  useSettings
} from '../../hooks'
import { TwoCheckoutContent } from '../TwoCheckoutContent'
import { BillingFormContent } from './billingFormContent'
import { Spinner } from '../Spinner'
import { ExpressCheckout } from '../ExpressCheckout'
import { ErrorMessagePage } from '../ErrorMessagePage'
import { fetchBillingInfoAction, fetchSettingsAction } from '../../actions'
import { ThemeProvider } from 'styled-components'
import { StyledBillingForm } from './styles'
import { isTwoCheckout } from '../../lib/twoCheckoutHelper'
import { useTheme as useVeneerTheme } from '@veneer/theme'
import { ErrorType } from '../../types'

const BillingContent = () => {
  const dispatch = useDispatch()
  const billingInfo = useBillingInfo()
  const settings = useSettings()
  const { billingInfoError, settingsError, enableExpressCheckout } =
    useBillingFormState()
  const [ready, setReady] = useState(false)
  const paymentMethods = billingInfo?.paymentMethods

  useEffect(() => {
    if (!ready && billingInfo && settings) {
      setReady(true)
    }
  }, [ready, billingInfo, settings])

  const onRetry = async () => {
    const promises: Promise<unknown>[] = []
    const errors = {
      billingInfo: {
        error: billingInfoError,
        fetchAction: fetchBillingInfoAction
      },
      settings: {
        error: settingsError,
        fetchAction: fetchSettingsAction
      }
    }

    Object.values(errors).forEach((error) => {
      if (error.error) {
        promises.push(dispatch(error.fetchAction()))
      }
    })

    try {
      await Promise.all(promises)
    } catch {
      // do nothing
    }
  }

  if (!ready) {
    if (billingInfoError || settingsError) {
      return <ErrorMessagePage onRetry={onRetry} />
    }
    return <Spinner />
  }

  if (isTwoCheckout(paymentMethods)) {
    return <TwoCheckoutContent />
  }

  if (enableExpressCheckout) {
    return <ExpressCheckout />
  }

  return <BillingFormContent />
}

export const BillingFormContainer = () => {
  const criticalScopes = useCriticalScopes()
  const containerSize = useContainerSize()
  const { criticalScopesError, settings, onSave, trackClickEvent } =
    useBillingFormState()
  const { color } = useVeneerTheme()
  const expiredCriticalScope = criticalScopesError?.response?.status === 403

  useEffect(() => {
    if (expiredCriticalScope && onSave) {
      onSave(ErrorType.expired_critical_scope)
    }
  }, [expiredCriticalScope, onSave])

  return (
    <ThemeProvider
      theme={{
        containerSize,
        updatedLayout: settings?.enableBillingUpdatedLayout,
        color
      }}
    >
      <StyledBillingForm
        onClick={(event) => {
          try {
            const linkId = (event.target as Element).getAttribute(
              'data-analyticsid'
            )
            if (linkId && trackClickEvent) {
              trackClickEvent(linkId, 'payment-information')
            }
          } catch {
            // do nothing
          }
        }}
      >
        {criticalScopes ? <BillingContent /> : <Spinner />}
      </StyledBillingForm>
    </ThemeProvider>
  )
}
