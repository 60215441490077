import { useEffect, useState } from 'react'

declare global {
  interface Window {
    ApplePaySession?: {
      canMakePayments: () => boolean
    }
    MSStream?: unknown
  }
}

export const useApplePay = () => {
  const [applePayAvailable, setApplePayAvailable] = useState(false)

  useEffect(() => {
    const appleDevices = ['iPad', 'iPhone', 'iPod', 'Macintosh']

    const isIOS =
      (appleDevices.some((device) => navigator.userAgent.includes(device)) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) &&
      !window.MSStream

    if (isIOS) {
      const script = document.createElement('script')
      script.src =
        'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js'
      script.onload = () => {
        if (
          window.ApplePaySession &&
          window.ApplePaySession.canMakePayments()
        ) {
          setApplePayAvailable(true)
        }
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  return applePayAvailable
}
