import React, { FC } from 'react'

import VeneerModal from '@veneer/core/dist/scripts/modal'
import { IconInterface } from '@veneer/core/dist/scripts/icons/types'
import * as Styled from './styles'
import { ModalState } from '../LinkPaymentProvider/linkPaymentProvider'
import IconChecmarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'
import IconLink from '@veneer/core/dist/scripts/icons/icon_link'
import { useGetText } from '../../hooks'
import { PayPalSuccessContent } from '../PayPal/payPalSuccessContent'

type ModalConfig = {
  label: string
  buttonText: string
  Icon: React.ForwardRefExoticComponent<
    IconInterface &
      Omit<React.SVGAttributes<SVGSVGElement>, keyof IconInterface> &
      React.RefAttributes<SVGSVGElement>
  >
  testId: string
}

const modalConfig: Omit<
  Record<ModalState, ModalConfig>,
  'paypalSuccess' | 'closed'
> = {
  checkStatus: {
    label: 'link_payment_page.status_check_modal.default',
    buttonText: 'link_payment_page.status_check_modal.check_button',
    testId: 'check-status-modal',
    Icon: IconLink
  },
  gpaySuccess: {
    label: 'link_payment_page.status_check_modal.successful.g_pay',
    buttonText: 'link_payment_page.status_check_modal.done_button',
    testId: 'gpay-success-modal',
    Icon: IconChecmarkCircle
  },
  applePaySuccess: {
    label: 'link_payment_page.status_check_modal:successful.apple_pay', //TODO: Fix lokalise key
    buttonText: 'link_payment_page.status_check_modal.done_button',
    testId: 'applepay-success-modal',
    Icon: IconChecmarkCircle
  }
}

interface StatusModalProps {
  closeModal: () => void
  onDone: () => void
  onCheckStatus: () => void
  show: boolean
  confirmButtonLoading?: boolean
  modalState: ModalState
  ['data-testid']?: string
}

export const StatusModal: FC<StatusModalProps> = ({
  closeModal,
  show,
  onCheckStatus,
  onDone,
  confirmButtonLoading,
  modalState,
  ...props
}) => {
  const getText = useGetText()

  const getButtonFunction = () => {
    switch (modalState) {
      case 'checkStatus':
        return onCheckStatus
      case 'gpaySuccess':
      case 'applePaySuccess':
      case 'paypalSuccess':
        return onDone
      default:
        return closeModal
    }
  }

  const getModalContent = () => {
    if (modalState == 'closed') return

    if (modalState == 'paypalSuccess') {
      return <PayPalSuccessContent closeModal={closeModal} />
    }

    const { Icon, buttonText, label, testId } = modalConfig[modalState]

    return (
      <Styled.ModalContainer data-testid={testId}>
        <Icon color="black" size={40} />
        <Styled.Label>{getText(label)}</Styled.Label>
        <Styled.ConfirmButton
          appearance="secondary"
          onClick={getButtonFunction()}
          loading={confirmButtonLoading}
        >
          {getText(buttonText)}
        </Styled.ConfirmButton>
      </Styled.ModalContainer>
    )
  }

  return (
    <VeneerModal
      data-testid={props['data-testid']}
      show={show}
      closeButton={true}
      closeOnBlur={false}
      onClose={closeModal}
    >
      {getModalContent()}
    </VeneerModal>
  )
}
