import styled from 'styled-components'

export const StyledSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 72px;
  padding-top: 48px;
`

export const HpxStyledSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0;
`

export const LottieSpinner = styled.div`
  height: 26px;
  width: 26px;
  margin-bottom: 26px;
`
