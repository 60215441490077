import AssetsProviderFactory from '../assets/AssetsProviderFactory'
import {
  BillingFlowSteps,
  BillingFormAction,
  BillingFormActionType,
  BillingFormState,
  BillingAddress
} from '../types'
import { BillingService } from '../lib/billingService'
import { AddressValidator, ValidateFieldOptions } from '../lib/addressValidator'
import { MockBillingService } from '../mock/lib/mockBillingService'
import { BillingFormProps } from '../components/BillingForm'
import { StratusService } from '../lib/stratusService'
import { MockStratusService } from '../mock/lib/mockStratusService'
import { Stack } from '@jarvis/web-stratus-client'

let counter = 0
const generateId = () => counter++

const defaultOnboardingcenterURL = (stack: Stack) => {
  switch (stack) {
    case Stack.prod:
      return 'https://start.hp.com'
    case Stack.stage:
      return 'https://onboardingcenter.stage.portalshell.int.hp.com'
    default:
      return 'https://onboardingcenter.pie.portalshell.int.hp.com'
  }
}

export const billingFormInitialState = ({
  country,
  language,
  stack,
  baseURLProvider,
  authProvider,
  xCorrelationId,
  mockStratus,
  mockFixtures,
  appName = 'none',
  ...props
}: BillingFormProps): BillingFormState => ({
  id: generateId(),
  assetsProvider: AssetsProviderFactory.create(language, country),
  billingService: mockStratus
    ? new MockBillingService(mockFixtures)
    : new BillingService(authProvider, stack, baseURLProvider, xCorrelationId),
  stratusService: mockStratus
    ? new MockStratusService(mockFixtures)
    : new StratusService(authProvider, stack),
  country,
  language,
  stack,
  errorFields: new Set(),
  italyTaxFieldType: undefined,
  billingFlowStepHistory: [BillingFlowSteps.STEP_ONE],
  appName,
  errorNotification: false,
  onboardingcenterURL: defaultOnboardingcenterURL(stack),
  linkPaymentSuccessStatus:
    (mockFixtures?.getFixture(
      'linkPaymentSuccessStatus'
    ) as unknown as boolean) || false,
  ...props
})

export const billingFormReducer = (
  state: BillingFormState,
  action: BillingFormAction
) => {
  const {
    SET_ASSETS_PROVIDER,
    SET_PAYMENT_TYPE,
    SET_BILLING_ADDRESS,
    SET_CONTAINTER_SIZE,
    SET_ITALY_TAX_FIELD,
    SET_ERROR_NOTIFICATION,
    VALIDATE_BILLING_ADDRESS,
    FETCH_CRITICAL_SCOPES,
    FETCH_CRITICAL_SCOPES_SUCCESS,
    FETCH_CRITICAL_SCOPES_FAIL,
    FETCH_SETTINGS,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAIL,
    FETCH_TAXPAYER_DETAILS,
    FETCH_TAXPAYER_DETAILS_SUCCESS,
    FETCH_TAXPAYER_DETAILS_FAIL,
    VALIDATE_TAXPAYER_DETAILS,
    UPDATE_TAXPAYER_DETAIL_ACCOUNT_TYPE,
    UPDATE_TAXPAYER_DETAIL,
    CLEAR_ITALY_TAXPAYER_DETAILS,
    SAVE_TAXPAYER_DETAILS,
    SAVE_TAXPAYER_DETAILS_SUCCESS,
    SAVE_TAXPAYER_DETAILS_FAIL,
    FETCH_BILLING_INFO,
    FETCH_BILLING_INFO_SUCCESS,
    FETCH_BILLING_INFO_FAIL,
    FETCH_BILLING_ADDRESS,
    FETCH_BILLING_ADDRESS_SUCCESS,
    FETCH_BILLING_ADDRESS_FAIL,
    SAVE_BILLING_ADDRESS,
    SAVE_BILLING_ADDRESS_SUCCESS,
    SAVE_BILLING_ADDRESS_FAIL,
    FETCH_SHIPPING_ADDRESS,
    FETCH_SHIPPING_ADDRESS_SUCCESS,
    FETCH_SHIPPING_ADDRESS_FAIL,
    FETCH_USER_ACCOUNT,
    FETCH_USER_ACCOUNT_SUCCESS,
    FETCH_USER_ACCOUNT_FAIL,
    FETCH_PHC_EVENT_STATUS,
    FETCH_PHC_EVENT_STATUS_SUCCESS,
    FETCH_PHC_EVENT_STATUS_FAIL,
    CREATE_PHC_EVENT_STATUS,
    CREATE_PHC_EVENT_STATUS_SUCCESS,
    CREATE_PHC_EVENT_STATUS_FAIL,
    PUSH_CURRENT_STEP,
    POP_CURRENT_STEP,
    SET_LINK_PAYMENT_SUCCESS_STATUS
  } = BillingFormActionType
  const {
    country,
    billingAddress,
    taxpayerDetails,
    billingInfo,
    errorFields,
    billingFlowStepHistory,
    settings
  } = state
  const addressValidator = new AddressValidator(country)

  const validateField = (
    field: string,
    value?: string,
    options?: ValidateFieldOptions
  ) => {
    if (addressValidator.validateField(field, value, options)) {
      errorFields?.delete(field)
    } else {
      errorFields?.add(field)
    }
  }

  const clearTaxpayerDetailErrors = () => {
    if (settings) {
      ;[
        settings.billingAccountTypeFields.consumer,
        settings.billingAccountTypeFields.business
      ].forEach((fields) => {
        ;[...fields.optional, ...fields.required].forEach((field) => {
          if (field === 'taxIdOrNonProfitTaxId') {
            errorFields?.delete('taxId')
            errorFields?.delete('nonProfitTaxId')
          } else {
            errorFields?.delete(field)
          }
        })
      })
    }
  }

  let newState = state
  const { field, value, requiredFields, ignoreEmptyFields } = action

  switch (action.type) {
    case SET_ASSETS_PROVIDER:
      newState = {
        ...state,
        assetsProvider: AssetsProviderFactory.create(
          action.language as string,
          action.country as string
        )
      }
      break
    case SET_PAYMENT_TYPE:
      newState = {
        ...state,
        paymentType: action.paymentType
      }
      break
    case SET_BILLING_ADDRESS:
      newState = {
        ...state,
        billingAddress: action.address
      }
      break
    case SET_CONTAINTER_SIZE:
      newState = {
        ...state,
        containerSize: action.containerSize
      }
      break
    case SET_ITALY_TAX_FIELD:
      newState = {
        ...state,
        italyTaxFieldType: action.italyTaxFieldType || 'taxId'
      }
      break
    case SET_ERROR_NOTIFICATION:
      newState = {
        ...state,
        errorNotification: action.errorNotification
      }
      break
    case SET_LINK_PAYMENT_SUCCESS_STATUS:
      newState = {
        ...state,
        linkPaymentSuccessStatus: action.linkPaymentSuccessStatus
      }
      break
    case VALIDATE_BILLING_ADDRESS:
      if (field) {
        validateField(field, value)
        newState = {
          ...state,
          billingAddress: {
            ...billingAddress,
            [field]: value
          } as BillingAddress,
          billingAddressError: undefined,
          errorFields: new Set(errorFields)
        }
      } else {
        requiredFields?.forEach((field) => {
          validateField(field, billingAddress?.[field])
        })
        newState = {
          ...state,
          billingAddressError: undefined,
          errorFields: new Set(errorFields)
        }
      }
      break
    case FETCH_CRITICAL_SCOPES:
      newState = {
        ...state,
        criticalScopesLoading: true,
        criticalScopesError: undefined
      }
      break
    case FETCH_CRITICAL_SCOPES_SUCCESS:
      newState = {
        ...state,
        criticalScopesLoading: false,
        criticalScopes: action.response?.data
      }
      break
    case FETCH_CRITICAL_SCOPES_FAIL:
      newState = {
        ...state,
        criticalScopesLoading: false,
        criticalScopesError: action.error
      }
      break
    case FETCH_SETTINGS:
      newState = {
        ...state,
        settingsLoading: true,
        settingsError: undefined
      }
      break
    case FETCH_SETTINGS_SUCCESS:
      newState = {
        ...state,
        settingsLoading: false,
        settings: action.response?.data
      }
      break
    case FETCH_SETTINGS_FAIL:
      newState = {
        ...state,
        settingsLoading: false,
        settingsError: action.error
      }
      break
    case FETCH_TAXPAYER_DETAILS:
      newState = {
        ...state,
        taxpayerDetailsLoading: true,
        taxpayerDetailsError: undefined
      }
      break
    case FETCH_TAXPAYER_DETAILS_SUCCESS: {
      newState = {
        ...state,
        taxpayerDetailsLoading: false,
        taxpayerDetails: action.response?.data
      }
      break
    }
    case FETCH_TAXPAYER_DETAILS_FAIL: {
      newState = {
        ...state,
        taxpayerDetailsLoading: false,
        taxpayerDetailsError: action.error
      }
      break
    }
    case VALIDATE_TAXPAYER_DETAILS: {
      if (settings?.enableBillingAccountType && taxpayerDetails?.accountType) {
        const { required, optional } =
          settings.billingAccountTypeFields[taxpayerDetails.accountType]
        const allFields = [...required, ...optional]
        const isRequired = (field: string) => required.includes(field)
        clearTaxpayerDetailErrors()
        allFields.forEach((field) => {
          const fieldToValidate =
            field === 'taxIdOrNonProfitTaxId' ? state.italyTaxFieldType : field

          if (
            fieldToValidate !== undefined &&
            (!ignoreEmptyFields ||
              taxpayerDetails[fieldToValidate] !== undefined)
          ) {
            validateField(fieldToValidate, taxpayerDetails[fieldToValidate], {
              accountType: taxpayerDetails.accountType,
              required: isRequired(field),
              useValidationScript: settings?.enableSpainTaxIdValidation
            })
          }
        })
        newState = {
          ...state,
          errorFields: new Set(errorFields)
        }
      }
      break
    }
    case UPDATE_TAXPAYER_DETAIL_ACCOUNT_TYPE: {
      if (settings?.enableBillingAccountType) {
        newState = {
          ...state,
          taxpayerDetails: action.taxpayerDetails
        }
      }
      break
    }
    case UPDATE_TAXPAYER_DETAIL:
      if (
        field &&
        settings?.enableBillingAccountType &&
        taxpayerDetails?.accountType
      ) {
        const { required } =
          settings.billingAccountTypeFields[taxpayerDetails.accountType]
        validateField(field, value, {
          accountType: taxpayerDetails.accountType,
          required: required.includes(field),
          useValidationScript: settings?.enableSpainTaxIdValidation
        })
        newState = {
          ...state,
          taxpayerDetails: { ...taxpayerDetails, [field]: value },
          errorFields: new Set(errorFields),
          taxpayerDetailsError: undefined
        }
      }
      break
    case CLEAR_ITALY_TAXPAYER_DETAILS:
      clearTaxpayerDetailErrors()
      newState = {
        ...state,
        taxpayerDetails: {
          ...taxpayerDetails,
          taxId: undefined,
          nonProfitTaxId: undefined
        },
        errorFields: new Set(errorFields)
      }
      break
    case SAVE_TAXPAYER_DETAILS:
      newState = {
        ...state,
        taxpayerDetailsSaving: true,
        taxpayerDetailsError: undefined
      }
      break
    case SAVE_TAXPAYER_DETAILS_SUCCESS:
      newState = {
        ...state,
        taxpayerDetailsSaving: false
      }
      break
    case SAVE_TAXPAYER_DETAILS_FAIL: {
      newState = {
        ...state,
        taxpayerDetailsSaving: false,
        taxpayerDetailsError: action.error
      }
      break
    }
    case FETCH_BILLING_INFO:
      newState = {
        ...state,
        billingInfoLoading: true,
        billingInfoError: undefined
      }
      break
    case FETCH_BILLING_INFO_SUCCESS:
      newState = {
        ...state,
        billingInfoLoading: false,
        billingInfo: action.response?.data
      }
      break
    case FETCH_BILLING_INFO_FAIL:
      newState = {
        ...state,
        billingInfoLoading: false,
        billingInfoError: action.error
      }
      break
    case FETCH_BILLING_ADDRESS:
      newState = {
        ...state,
        billingAddressLoading: true,
        billingAddressError: undefined
      }
      break
    case FETCH_BILLING_ADDRESS_SUCCESS:
      newState = {
        ...state,
        billingAddressLoading: false,
        billingAddress: action.response?.data || { countryCode: country },
        originalBillingAddress: action.response?.data
          ? { ...action.response?.data }
          : null
      }
      break
    case FETCH_BILLING_ADDRESS_FAIL:
      newState = {
        ...state,
        billingAddressLoading: false,
        billingAddressError: action.error
      }
      break
    case SAVE_BILLING_ADDRESS:
      newState = {
        ...state,
        billingAddressSaving: true,
        billingAddressError: undefined
      }
      break
    case SAVE_BILLING_ADDRESS_SUCCESS:
      newState = {
        ...state,
        billingAddressSaving: false
      }
      break
    case SAVE_BILLING_ADDRESS_FAIL: {
      newState = {
        ...state,
        billingAddressSaving: false,
        billingAddressError: action.error
      }
      break
    }
    case FETCH_SHIPPING_ADDRESS:
      newState = {
        ...state,
        shippingAddressLoading: true,
        shippingAddressError: undefined
      }
      break
    case FETCH_SHIPPING_ADDRESS_SUCCESS:
      newState = {
        ...state,
        shippingAddressLoading: false,
        shippingAddress: action.response?.data || null
      }
      break
    case FETCH_SHIPPING_ADDRESS_FAIL:
      newState = {
        ...state,
        shippingAddressLoading: false,
        shippingAddressError: action.error
      }
      break
    case FETCH_USER_ACCOUNT:
      newState = {
        ...state,
        userAccountLoading: true,
        userAccountError: undefined
      }
      break
    case FETCH_USER_ACCOUNT_SUCCESS:
      newState = {
        ...state,
        userAccountLoading: false,
        userAccount: action.response?.data
      }
      break
    case FETCH_USER_ACCOUNT_FAIL:
      newState = {
        ...state,
        userAccountLoading: false,
        userAccountError: action.error
      }
      break
    case CREATE_PHC_EVENT_STATUS:
    case FETCH_PHC_EVENT_STATUS:
      newState = {
        ...state,
        phcEventStatusLoading: true,
        phcEventStatusError: undefined
      }
      break
    case FETCH_PHC_EVENT_STATUS_SUCCESS:
      newState = {
        ...state,
        phcEventStatusLoading: false,
        phcEventStatus: action.response?.data?.status
      }
      break
    case CREATE_PHC_EVENT_STATUS_FAIL:
    case FETCH_PHC_EVENT_STATUS_FAIL:
      newState = {
        ...state,
        phcEventStatusLoading: false,
        phcEventStatusError: action.error
      }
      break
    case CREATE_PHC_EVENT_STATUS_SUCCESS:
      newState = {
        ...state,
        phcEventStatusLoading: false,
        phcEventStatus: undefined,
        billingInfo: {
          ...billingInfo,
          orderId: action.response?.data?.orderID
        }
      }
      break
    case PUSH_CURRENT_STEP:
      if (action.currentStep) {
        billingFlowStepHistory.push(action.currentStep)

        newState = {
          ...state,
          billingFlowStepHistory
        }
      }
      break
    case POP_CURRENT_STEP:
      billingFlowStepHistory.pop()

      newState = {
        ...state,
        billingFlowStepHistory
      }
      break
  }

  return newState
}
